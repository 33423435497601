import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import app from './modules/app';
import permission from './modules/permission';
import settings from './modules/settings';
import user from './modules/user';
import tagsView from './modules/tagsView';
Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    app,
    permission,
    settings,
    user,
    tagsView
  },
  getters,
  state: {
    userId: 5,
    theme: '',
    alarmId: '',
    tokenFlag: false,
    //true为token失效
    loginAgain: false,
    position: []
  },
  mutations: {
    updateUserId(state, id) {
      state.userId = id;
    },
    changeTheme(state, val) {
      state.theme = val;
    },
    changeAlarmId(state, val) {
      state.alarmId = val;
    },
    changeToken(state, val) {
      state.tokenFlag = val;
    },
    changeLogin(state, val) {
      state.loginAgain = val;
    },
    setLocation(state, val) {
      state.position = val;
      // console.log(val,'store')
    }
  }
});
export default store;