// 路由是否是外部链接  若为外部链接 不在侧边栏显示
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
// 验证登录页面 用户名 是否在有效的用户名数组中
export function validUsername(str) {
  const valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}
// 验证手机号
export function validMobile(str) {
  if (str.trim().length == 11) {
    // /^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9])[0-9]{8}$/
    return /^1[13456789]\d{9}$/.test(str.trim());
  } else {
    return false;
  }
}
// 验证身份证号
export function validId(str) {
  return /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(str.trim());
}
export function checkPassport() {
  // if (!code || !/^1[45][0-9]{7}$|(^[P|p|S|s]\d{7}$)|(^[S|s|G|g|E|e]\d{8}$)|(^[Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8}$)|(^[H|h|M|m]\d{8,10}$)/.test(code)) {
  //   return false
  // }
  return true;
}

// 验证车牌号
export function validCarNum(str) {
  if (str.trim().length == 7) {
    return /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/.test(str.trim());
  } else {
    return false;
  }
}