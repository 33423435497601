import "core-js/modules/es.array.push.js";
import { login, getUserData } from '@/api/user';
import { resetRouter, asyncRoutes } from '@/router';
import { get, forEach } from 'lodash';
const state = {
  userId: undefined,
  token: '',
  name: '',
  roles: [],
  userinfo: {},
  isLogout: false,
  shops: []
};
const mutations = {
  SET_USER_ID: (state, id) => {
    state.userId = id;
  },
  SET_TOKEN: (state_in, token) => {
    state_in.token = token;
  },
  SET_SHOPS: (state, shops) => {
    state.shops = shops;
  },
  SET_AVATAR: (state_in, avatar) => {
    state_in.avatar = avatar;
  },
  SET_ROLES: (state_in, roles) => {
    state_in.roles = roles;
  },
  SET_LOGOUT: (state_in, isLogout) => {
    state_in.isLogout = isLogout;
  }
};
const actions = {
  async login({
    commit
  }, userInfo) {
    try {
      let loginRes = await login({
        ...userInfo
      });
      if (loginRes && loginRes.result == 0) {
        if (loginRes.data) {
          sessionStorage.setItem('saas-token', loginRes.data);
        }
        let userRes = await getUserData();
        let roles = [];
        if (userRes.data.allPrivileges && userRes.data.allPrivileges.length > 0) {
          let allPrivileges = userRes.data.allPrivileges; // allPrivileges字段没有返回，另外本项目的saas-roles未用上，所以处理比较乱后续优化
          for (let i = 0; i < allPrivileges.length; i++) {
            roles.push(`${allPrivileges[i].id}`);
          }
        } else {
          roles = ['1'];
        }
        commit('SET_USER_ID', get(userRes, 'data.id'));
        commit('SET_TOKEN', loginRes.data);
        commit('SET_SHOPS', get(userRes, 'data.shops', []));
        commit('SET_LOGOUT', false);
        sessionStorage.setItem('user-info', JSON.stringify(userRes.data));
        sessionStorage.setItem('saas-roles', JSON.stringify(roles));
        // 如果是单点登录，隐藏人口管理和房屋管理
        let ticket = sessionStorage.getItem('ticket') || '';
        if (ticket) {
          userRes.data.menus.pcMenu = (userRes.data.menus.pcMenu || []).filter(item => item.menuName != '人口管理' && item.menuName != '房屋管理');
        }
        let menuArr = userRes.data.menus.pcMenu || [];
        let routhPath = [];
        if (menuArr.length) {
          routhPath.push(asyncRoutes.find(item => item.meta.title == menuArr[0].menuName).path);
        } else {
          routhPath = ['/'];
        }
        userRes.path = '';
        let numberArr = roles.map(Number);
        numberArr.sort((a, b) => {
          if (a < b) return -1;else if (a > b) return 1;else return 0;
        });
        roles = numberArr.map(String);
        if (roles.length > 0 && parseInt(roles[0]) > 0 && parseInt(roles[0]) < 6) {
          userRes.path = routhPath[parseInt(roles[0]) - 1];
        }
        return userRes;
      } else {
        return loginRes;
      }
    } catch (e) {
      return {
        result: 1,
        msg: '网络错误'
      };
    }
  },
  // user logout
  logout({
    commit
  }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      commit('SET_LOGOUT', true);
      resetRouter();
      sessionStorage.removeItem('saas-token');
      sessionStorage.removeItem('saas-roles');
      sessionStorage.removeItem('user-info');
      sessionStorage.removeItem('ticket');
      sessionStorage.setItem('theme', 'white');
      resolve();
    });
  },
  // get user info
  async getInfo() {
    const {
      data: res
    } = await getUserData();
    let resMenu = get(res, 'menus.pcMenu', []);
    // 如果是单点登录，隐藏人口管理和房屋管理
    let ticket = sessionStorage.getItem('ticket') || '';
    if (ticket) {
      resMenu = resMenu.filter(item => item.menuName != '人口管理' && item.menuName != '房屋管理');
    }
    let menus = [];
    forEach(resMenu, ele => {
      menus.push(ele.menuName);
      forEach(ele.children, _ele => {
        menus.push(_ele.menuName);
      });
    });
    const permissions = get(res, 'permissions');
    return {
      menus,
      permissions
    };
  },
  // remove token
  resetToken({
    commit,
    dispatch
  }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      dispatch('tagsView/delAllViews', null, {
        root: true
      });
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};