export default {
  name: 'App',
  components: {},
  data: function () {
    return {
      userId: sessionStorage.getItem('user-info')
    };
  },
  created() {
    try {
      const {
        id
      } = JSON.parse(sessionStorage.getItem('user-info'));
      this.$store.commit('user/SET_USER_ID', id);
    } catch (e) {
      console.error(e);
    }
  },
  mounted() {
    window.addEventListener('beforeunload', e => {
      this.unloadFun(e);
    });
  },
  destroyed() {
    window.removeEventListener('beforeunload', e => {
      this.unloadFun(e);
    });
  },
  methods: {
    unloadFun() {
      sessionStorage.removeItem('refreshList');
    }
  }
};