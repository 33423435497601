import "core-js/modules/es.array.push.js";
import { get, head, filter, size, findIndex, toString } from 'lodash';
import { mapGetters } from 'vuex';
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCollapse: false,
      routeArr: [],
      defaultActive: '1'
    };
  },
  computed: {
    ...mapGetters(['permission_routes']),
    activeIdx() {
      let childIdx = 0;
      const idx = toString(findIndex(this.routeArr, ele => {
        const _idx = findIndex(ele.children, ({
          name,
          children
        }) => name === this.$route.name || get(children, '[0].name') === this.$route.name);
        if (size(filter(ele.children, e => !e.hidden)) > 1) {
          childIdx = _idx + 1;
        }
        return _idx >= 0;
      }) + 1);
      return `${idx}${childIdx ? `-${childIdx}` : ''}`;
    }
  },
  mounted() {
    this.routeArr = this.permission_routes.filter(k => !k.hidden);
  },
  methods: {
    size,
    filter,
    head,
    getChildren(childrenArr) {
      return childrenArr.filter(d => !d.hidden);
    },
    routerNext(res) {
      if (!this.$isEmpty(res.children) && !res.path) {
        this.$router.push({
          path: filter(res.children, ele => !ele.hidden)[0].path
        });
        return;
      }
      this.$router.push({
        path: res.path
      });
    }
  }
};