import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
import Layout from '@/layout';
export const constantRoutes = [{
  path: '/',
  redirect: '/population',
  hidden: true
}, {
  path: '/login',
  component: () => import('@/views/login/index'),
  hidden: true
}];
export const asyncRoutes = [{
  path: '/population',
  component: Layout,
  redirect: '/population',
  meta: {
    title: '人口管理',
    icon: 'population',
    role: ['1']
  },
  children: [{
    path: '/population',
    name: 'PopulationManagement',
    component: () => import('@/views/PopulationManagement'),
    meta: {
      keepAlive: true
    }
  }, {
    path: '/population/detail',
    name: 'PopulationDetail',
    hidden: true,
    component: () => import('@/views/PopulationManagement/Detail'),
    meta: {
      title: '人员详情',
      isDetail: true
    }
  }]
}, {
  path: '/float',
  component: Layout,
  redirect: '/float',
  meta: {
    title: '外来人口管理',
    icon: 'population',
    role: ['1']
  },
  children: [{
    path: '/float',
    name: 'FloatManagement',
    component: () => import('@/views/FloatManagement'),
    meta: {
      keepAlive: true
    }
  }, {
    path: '/float/detail',
    name: 'FloatDetail',
    hidden: true,
    component: () => import('@/views/FloatManagement/Detail'),
    meta: {
      title: '人员详情',
      isDetail: true
    }
  }]
},
// {
//   path: '/group',
//   component: Layout,
//   redirect: '/group',
//   meta: { title: '组别管理', icon: 'population', role: ['1'] },
//   children: [
//     {
//       path: '/group',
//       name: 'GroupManagement',
//       component: () => import('@/views/GroupManagement'),
//       meta: { keepAlive: true }
//     }
//   ]
// },
{
  path: '/enterprise',
  component: Layout,
  redirect: '/enterprise',
  meta: {
    title: '企业管理',
    icon: 'boutique',
    role: ['1']
  },
  children: [{
    path: '/enterprise',
    name: 'EnterpriseManagement',
    component: () => import('@/views/EnterpriseManagement'),
    meta: {
      keepAlive: true
    }
  }, {
    path: '/enterprise/detail',
    name: 'EnterpriseDetail',
    hidden: true,
    component: () => import('@/views/EnterpriseManagement/Detail'),
    meta: {
      title: '企业详情',
      isDetail: true
    }
  }]
}, {
  path: '/point',
  component: Layout,
  redirect: '/point',
  meta: {
    title: '积分管理',
    icon: 'work-order'
  },
  children: [{
    path: '/point',
    name: 'point',
    component: () => import('@/views/PointManagement/point'),
    meta: {
      title: '积分管理',
      keepAlive: true
    },
    children: [{
      path: '/point/detail',
      name: 'PointDetail',
      component: () => import('@/views/PointManagement/pointDetail'),
      meta: {
        title: '积分详情',
        isDetail: true
      }
    }]
  }, {
    path: '/random',
    name: 'random',
    component: () => import('@/views/PointManagement/random'),
    meta: {
      title: '事件管理',
      keepAlive: true
    },
    children: [{
      path: '/random/detail',
      name: 'RandomDetail',
      component: () => import('@/views/PointManagement/randomDetail'),
      meta: {
        title: '事件详情',
        isDetail: true
      }
    }]
  }]
}, {
  path: '/car',
  component: Layout,
  redirect: '/car',
  meta: {
    title: '车辆管理',
    icon: 'tag',
    role: ['1']
  },
  children: [{
    path: '/car',
    name: 'CarManagement',
    component: () => import('@/views/CarManagement'),
    meta: {
      keepAlive: true
    }
  }, {
    path: '/car/detail',
    name: 'CarDetail',
    hidden: true,
    component: () => import('@/views/CarManagement/Detail'),
    meta: {
      title: '车辆详情',
      isDetail: true
    }
  }]
}, {
  path: '/info',
  component: Layout,
  redirect: '/release',
  meta: {
    title: '信息管理',
    icon: 'building'
  },
  children: [{
    path: '/release',
    name: 'InfoManagerment',
    component: () => import('@/views/InfoManagerment/publish/index'),
    meta: {
      title: '信息发布'
    },
    children: [{
      path: '/release/new-publish',
      name: 'newPublish',
      component: () => import('@/views/InfoManagerment/publish/addPublish'),
      meta: {
        title: '',
        isDetail: true
      }
    }]
  }
  // {
  //   path: '/diss-manage-list',
  //   name: 'DissManageManagerment',
  //   component: () => import('@/views/InfoManagerment/dissManage/index'),
  //   meta: { title: '留言管理' }
  // }
  ]
}, {
  path: '/service',
  component: Layout,
  redirect: '/service',
  meta: {
    title: '服务力量',
    icon: 'population',
    role: ['1']
  },
  children: [{
    path: '/service',
    name: 'ServiceManagement',
    component: () => import('@/views/ServiceManagement'),
    meta: {
      keepAlive: true
    }
  }]
}, {
  path: '/tagmanagement',
  component: Layout,
  redirect: '/tagmanagement',
  meta: {
    title: '标签库管理',
    icon: 'tag'
  },
  children: [{
    path: '/tagmanagement',
    name: 'TagManagement',
    component: () => import('@/views/TagManagement')
  }]
}, {
  path: '/permission',
  component: Layout,
  redirect: '/role',
  meta: {
    title: '权限管理',
    icon: 'permission'
  },
  children: [{
    path: '/role',
    name: 'role',
    component: () => import('@/views/PermissionManagement/role'),
    meta: {
      title: '角色管理'
    }
  }, {
    path: '/account',
    name: 'account',
    component: () => import('@/views/PermissionManagement/account'),
    meta: {
      title: '账号管理'
    },
    children: [{
      path: '/account/detail',
      name: 'AccountDetail',
      component: () => import('@/views/PermissionManagement/accountDetail'),
      meta: {
        title: '账号编辑',
        isDetail: true
      }
    }]
  }]
}];
const createRouter = () => new Router({
  mode: 'history',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
});
const router = createRouter();
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}
export default router;