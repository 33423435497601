import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data
  });
}
// 获取个人信息
export function getUserData(query) {
  return request({
    url: '/users/me',
    method: 'get',
    params: query
  });
}