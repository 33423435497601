import "core-js/modules/es.array.push.js";
const state = {
  visitedViews: [],
  cachedViews: []
};
const mutations = {
  ADD_VISITED_VIEW: (state1, view) => {
    if (state1.visitedViews.some(v => v.path === view.path)) return;
    state1.visitedViews.push(Object.assign({}, view, {
      title: view.meta.title || 'no-name'
    }));
  },
  ADD_CACHED_VIEW: (state2, view) => {
    if (state2.cachedViews.includes(view.name)) return;
    if (!view.meta.noCache) {
      state2.cachedViews.push(view.name);
    }
  },
  DEL_VISITED_VIEW: (state3, view) => {
    for (const [i, v] of state3.visitedViews.entries()) {
      if (v.path === view.path) {
        state3.visitedViews.splice(i, 1);
        break;
      }
    }
  },
  DEL_CACHED_VIEW: (state4, view) => {
    const index = state4.cachedViews.indexOf(view.name);
    index > -1 && state4.cachedViews.splice(index, 1);
  },
  DEL_OTHERS_VISITED_VIEWS: (state5, view) => {
    state5.visitedViews = state5.visitedViews.filter(v => {
      return v.meta.affix || v.path === view.path;
    });
  },
  DEL_OTHERS_CACHED_VIEWS: (state6, view) => {
    const index = state6.cachedViews.indexOf(view.name);
    if (index > -1) {
      state6.cachedViews = state6.cachedViews.slice(index, index + 1);
    } else {
      // if index = -1, there is no cached tags
      state6.cachedViews = [];
    }
  },
  DEL_ALL_VISITED_VIEWS: state7 => {
    // keep affix tags
    const affixTags = state7.visitedViews.filter(tag => tag.meta.affix);
    state7.visitedViews = affixTags;
  },
  DEL_ALL_CACHED_VIEWS: state8 => {
    state8.cachedViews = [];
  },
  UPDATE_VISITED_VIEW: (state9, view) => {
    for (let v of state9.visitedViews) {
      if (v.path === view.path) {
        Object.assign(v, view);
        break;
      }
    }
  }
};
const actions = {
  addView({
    dispatch
  }, view) {
    dispatch('addVisitedView', view);
    dispatch('addCachedView', view);
  },
  addVisitedView({
    commit
  }, view) {
    commit('ADD_VISITED_VIEW', view);
  },
  addCachedView({
    commit
  }, view) {
    commit('ADD_CACHED_VIEW', view);
  },
  delView({
    dispatch,
    state1
  }, view) {
    return new Promise(resolve => {
      dispatch('delVisitedView', view);
      dispatch('delCachedView', view);
      resolve({
        visitedViews: [...state1.visitedViews],
        cachedViews: [...state1.cachedViews]
      });
    });
  },
  delVisitedView({
    commit,
    state2
  }, view) {
    return new Promise(resolve => {
      commit('DEL_VISITED_VIEW', view);
      resolve([...state2.visitedViews]);
    });
  },
  delCachedView({
    commit,
    state3
  }, view) {
    return new Promise(resolve => {
      commit('DEL_CACHED_VIEW', view);
      resolve([...state3.cachedViews]);
    });
  },
  delOthersViews({
    dispatch,
    state4
  }, view) {
    return new Promise(resolve => {
      dispatch('delOthersVisitedViews', view);
      dispatch('delOthersCachedViews', view);
      resolve({
        visitedViews: [...state4.visitedViews],
        cachedViews: [...state4.cachedViews]
      });
    });
  },
  delOthersVisitedViews({
    commit,
    state5
  }, view) {
    return new Promise(resolve => {
      commit('DEL_OTHERS_VISITED_VIEWS', view);
      resolve([...state5.visitedViews]);
    });
  },
  delOthersCachedViews({
    commit,
    state6
  }, view) {
    return new Promise(resolve => {
      commit('DEL_OTHERS_CACHED_VIEWS', view);
      resolve([...state6.cachedViews]);
    });
  },
  delAllViews({
    dispatch,
    state7
  }, view) {
    return new Promise(resolve => {
      dispatch('delAllVisitedViews', view);
      dispatch('delAllCachedViews', view);
      resolve({
        visitedViews: [...state7.visitedViews],
        cachedViews: [...state7.cachedViews]
      });
    });
  },
  delAllVisitedViews({
    commit,
    state8
  }) {
    return new Promise(resolve => {
      commit('DEL_ALL_VISITED_VIEWS');
      resolve([...state8.visitedViews]);
    });
  },
  delAllCachedViews({
    commit,
    state9
  }) {
    return new Promise(resolve => {
      commit('DEL_ALL_CACHED_VIEWS');
      resolve([...state9.cachedViews]);
    });
  },
  updateVisitedView({
    commit
  }, view) {
    commit('UPDATE_VISITED_VIEW', view);
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};