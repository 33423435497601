import defaultSettings from '@/settings';
const {
  showSettings,
  tagsView,
  fixedHeader,
  sidebarLogo
} = defaultSettings;
const state = {
  showSettings: showSettings,
  fixedHeader: fixedHeader,
  tagsView: tagsView,
  sidebarLogo: sidebarLogo
};
const mutations = {};
const actions = {};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};