import "core-js/modules/es.array.push.js";
import axios from 'axios';
import store from '../store';
import { Message } from 'element-ui';
import router from '@/router/index';
// 创建自定义的axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
});
var flag = 0;
const config = [];
// 添加请求拦截
service.interceptors.request.use(config => {
  if (sessionStorage.getItem('saas-token') && sessionStorage.getItem('saas-token') !== '') {
    config.headers['token'] = sessionStorage.getItem('saas-token');
  }
  return config;
}, error => {
  // 请求错误  抛出错误
  return Promise.reject(error);
});
service.interceptors.response.use(response => {
  const res = response.data;
  return res;
}, async error => {
  console.log(error);
  if (error.response.status == 403 || error.response.status == 401) {
    store.dispatch('user/logout');
    router.push({
      path: `/login`
    });
  } else if (error.response.status != 403 && error.response.status != 401) {
    debounce(reset(error.response), 2000);
  }
  return Promise.reject(error);
});
function debounce(fn, wait) {
  var timer = null;
  return function () {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(fn, wait);
  };
}
function reset(info) {
  if (info && info.status && info.status === 400) {
    store.commit('SET_TOKEN', '');
    store.commit('SET_ROLES', []);
    store.commit('SET_LOGOUT', true);
    sessionStorage.removeItem('saas-token');
    sessionStorage.removeItem('saas_refreshToken');
    sessionStorage.removeItem('saas-roles');
    // sessionStorage.removeItem('saas-userinfo')
    store.commit('changeToken', true);
    store.commit('changeLogin', false);
  } else if (info && info.status && info.status === 401) {
    Message({
      message: '无效登录，即将退回登录页',
      type: 'error',
      duration: 1000
    });
    store.dispatch('user/logout');
    router.push({
      path: `/login?comm_code=${sessionStorage.getItem('saas_comm_code')}`
    });
  } else if (info && info.status && info.status === 404) {
    Message({
      message: '没有找到访问路径',
      type: 'error',
      duration: 5 * 1000
    });
  } else if (info && info.status && info.status === 500) {
    Message({
      message: '服务器发生错误',
      type: 'error',
      duration: 5 * 1000
    });
  } else {
    Message({
      message: info.data.error,
      type: 'error',
      duration: 5 * 1000
    });
  }
  return 1;
}
async function request(...args) {
  let response = await service(...args);
  if (response == -1) {
    if (flag == 1) {
      config.push(Promise.resolve(request(...args)));
    } else {
      return request(...args);
    }
  }
  return response;
}
export default request;