import "core-js/modules/es.array.push.js";
import SideBar from './components/SideBar';
export default {
  components: {
    SideBar
  },
  data() {
    return {
      sidebarOpen: false,
      sidebar: false
    };
  },
  computed: {
    key() {
      return this.$route.path;
    },
    name() {
      if (JSON.parse(sessionStorage.getItem('user-info'))) {
        return JSON.parse(sessionStorage.getItem('user-info')).username;
      } else {
        return '';
      }
    }
  },
  methods: {
    toggleSideBar() {
      this.sidebar = !this.sidebar;
      this.sidebarOpen = !this.sidebarOpen;
    },
    handleCommand(command) {
      if (command === 'exit') {
        this.logout();
      }
    },
    logout() {
      this.$store.dispatch('user/logout');
      this.$router.push({
        path: `/login`
      });
    },
    routerChange() {
      if (this.$route.meta.parentPath) {
        this.$router.push({
          path: this.$route.meta.parentPath
        });
      }
    }
  }
};