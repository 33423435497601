import "core-js/modules/es.error.cause.js";
import Vue from 'vue';
import store from '@/store';
export const drag = Vue.directive('permission', {
  inserted(el, binding) {
    const {
      value
    } = binding;
    const all_permission = '*:*:*';
    const permissions = store.getters && store.getters.permissions;
    if (value && value instanceof Array && value.length > 0) {
      const permissionFlag = value;
      const hasPermissions = permissions.some(permission => {
        return all_permission === permission || permissionFlag.includes(permission);
      });
      if (!hasPermissions) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error(`请设置操作权限标签值`);
    }
  }
});