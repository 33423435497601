import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import { Message } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import '@/directives/el-draggable-dialog';
import '@/directives/click-outside';
import '@/directives/permission-check';
import '@/styles/index.scss'; //global
import '@/styles/element-ui.scss'; //global
import axios from 'axios';
import '@/icons'; // icon
import '@/permission'; // permission control
import infiniteScroll from 'vue-infinite-scroll';
import 'default-passive-events';
import { get, isEmpty } from 'lodash';
Vue.filter('getImg', uuid => {
  if (!uuid) {
    return '';
  }
  if (uuid && uuid.startsWith('http')) {
    return uuid;
  }
  return `${process.env.VUE_APP_IMG_URI}/download_self?uuid=${uuid}`;
});
Vue.prototype.$pageFormat = [10, 20, 50, 100];
Vue.prototype.$dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
Vue.prototype.$dateFormat = 'yyyy-MM-DD';
Vue.prototype.$get = get;
Vue.prototype.$isEmpty = isEmpty;
Vue.prototype.$axios = axios;
Vue.prototype.$echarts = window.echarts;
Vue.prototype.$message = Message;
Vue.use(infiniteScroll);
Vue.use(ElementUI, {
  zhLocale
});
Vue.config.productionTip = false;
hasTicket();
function hasTicket() {
  // 单点登录-获取字段ticket
  let url = window.location.search.substring(1);
  let ticket = '';
  let arr1 = url.split('#');
  let arr2 = arr1[0].split('&');
  for (var i = 0; i < arr2.length; i++) {
    var arr3 = arr2[i].split('=');
    if (arr3[0] == 'ticket') {
      ticket = arr3[1];
      break;
    }
  }
  if (ticket) {
    sessionStorage.setItem('ticket', ticket);
  }
}
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app');