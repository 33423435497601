var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "common-layout whole-w-h"
  }, [_c("div", {
    staticClass: "lay-main-title"
  }, [_c("div", {
    staticClass: "logo-box"
  }, [_c("svg-icon", {
    style: {
      height: "36px",
      width: "36px",
      marginRight: "20px"
    },
    attrs: {
      "icon-class": "unified"
    }
  }), _c("div", {
    staticClass: "logo-title fr"
  }, [_vm._v("苏民村综合数据管理平台")])], 1), _c("div", {
    staticClass: "lay-command"
  }, [_c("el-dropdown", {
    on: {
      command: _vm.handleCommand
    }
  }, [_c("span", {
    staticClass: "el-dropdown-link"
  }, [_c("em", {
    staticClass: "el-icon-user",
    staticStyle: {
      "margin-right": "10px"
    }
  }), _vm._v(_vm._s(_vm.name)), _c("em", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: "exit",
      icon: "el-icon-switch-button"
    }
  }, [_vm._v(" 退出登录 ")])], 1)], 1)], 1)]), _c("div", {
    staticClass: "lay-aside",
    class: _vm.sidebarOpen ? "active" : ""
  }, [_c("SideBar", {
    attrs: {
      "is-show": _vm.sidebar
    }
  })], 1), _c("div", {
    staticClass: "lay-main",
    class: _vm.sidebarOpen ? "active" : ""
  }, [_c("div", {
    staticClass: "lay-title"
  }, [_c("div", {
    staticClass: "main-title"
  }, [_vm.$route.matched[0].meta.title ? _c("span", [_vm._v(_vm._s(_vm.$route.matched[0].meta.title))]) : _vm._e(), _vm.$route.matched[0].meta.title && _vm.$route.meta.title ? _c("i", {
    staticClass: "el-icon-arrow-right"
  }) : _vm._e(), _c("span", {
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: function ($event) {
        return _vm.routerChange();
      }
    }
  }, [_vm._v(_vm._s(_vm.$route.meta.title))]), _vm.$route.meta.secondTitle ? _c("i", {
    staticClass: "el-icon-arrow-right"
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.$route.meta.secondTitle) + " ")]), _c("div", {
    staticClass: "main-next-title"
  }, [_vm._v(" " + _vm._s(_vm.$route.meta.title || _vm.$route.matched[0].meta.title) + " ")])]), _c("div", {
    staticClass: "lay-content"
  }, [_c("keep-alive", [_vm.$route.meta.keepAlive ? _c("router-view", {
    key: _vm.key
  }) : _vm._e()], 1), !_vm.$route.meta.keepAlive ? _c("router-view", {
    key: _vm.key
  }) : _vm._e()], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };