var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "lay-aside-box"
  }, [_c("div", {
    staticClass: "lay-menu"
  }, [_c("el-menu", {
    staticClass: "el-menu-vertical-demo",
    attrs: {
      "default-active": _vm.activeIdx,
      "unique-opened": true,
      collapse: _vm.isShow,
      "background-color": "transparent",
      "text-color": "#F9F9F9"
    }
  }, _vm._l(_vm.routeArr, function (item, index) {
    return _c("div", {
      key: ("indexs" + index).toString()
    }, [_vm.size(_vm.filter(item.children, ele => !ele.hidden)) === 1 ? _c("el-menu-item", {
      attrs: {
        index: (index + 1).toString()
      },
      on: {
        click: function ($event) {
          return _vm.routerNext(item);
        }
      }
    }, [_c("svg-icon", {
      attrs: {
        "icon-class": _vm.$get(item, "meta.icon", _vm.$get(_vm.head(item.children), "meta.icon"))
      }
    }), _c("span", {
      staticClass: "title",
      attrs: {
        slot: "title"
      },
      slot: "title"
    }, [_vm._v(" " + _vm._s(item.meta.title) + " ")])], 1) : _c("el-submenu", {
      attrs: {
        index: (index + 1).toString()
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("svg-icon", {
      attrs: {
        "icon-class": _vm.$get(item, "meta.icon", _vm.$get(_vm.head(item.children), "meta.icon"))
      }
    }), _c("span", {
      staticClass: "title",
      attrs: {
        slot: "title"
      },
      slot: "title"
    }, [_vm._v(" " + _vm._s(item.meta.title) + " ")])], 1), _c("el-menu-item-group", _vm._l(_vm.getChildren(item.children), function (itm, idx) {
      return _c("el-menu-item", {
        key: ("idxs" + idx).toString(),
        staticClass: "secondTitle",
        attrs: {
          index: index + 1 + "-" + (idx + 1)
        },
        on: {
          click: function ($event) {
            return _vm.routerNext(itm);
          }
        }
      }, [_c("span", {
        staticClass: "titles"
      }, [_vm._v(_vm._s(itm.meta.title))])]);
    }), 1)], 2)], 1);
  }), 0)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };