import router from './router';
import store from './store';
import { Message } from 'element-ui';
import NProgress from 'nprogress'; // 引入进度条
import 'nprogress/nprogress.css';
import getPageTitle from '@/utils/get-page-title';
NProgress.configure({
  showSpinner: false
});
const whiteList = ['/login']; // 路由白名单

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  document.title = getPageTitle(to.meta.title);
  const hasToken = sessionStorage.getItem('saas-token');
  if (hasToken && hasToken !== '') {
    if (to.path === '/login') {
      next('/');
      NProgress.done();
    } else {
      const hasRoles = store.getters.roles && store.getters.roles.length > 0;
      if (hasRoles) {
        next();
      } else {
        try {
          store.dispatch('user/getInfo').then(res => {
            res.roles = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
            store.commit('user/SET_ROLES', res.roles);
            store.commit('permission/updateMenu', res.menus);
            store.commit('permission/updatePermission', res.permissions);
            store.dispatch('permission/generateRoutes', {
              roles: res.roles
            }).then(datas => {
              for (let i = 0, length = datas.length; i < length; i++) {
                const element = datas[i];
                router.addRoute(element);
              }
              next();
              NProgress.done();
            });
          });
        } catch (error) {
          Message.error(error || 'Has Error');
          next(`/login`);
          NProgress.done();
        }
      }
      next();
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next('/login');
      NProgress.done();
    }
    NProgress.done();
  }
});
router.afterEach(() => {
  NProgress.done();
});