import "core-js/modules/es.array.push.js";
import { forEach, includes, get } from 'lodash';
import { asyncRoutes, constantRoutes } from '@/router';
function hasPermission(route, menus) {
  const routeTitle = get(route, 'meta.title');
  const isDetail = get(route, 'meta.isDetail');
  return includes(menus, routeTitle) || !routeTitle || isDetail || routeTitle == '组别管理' || routeTitle == '标签库管理';
}
export function filterAsyncRoutes(routes, menus) {
  const res = [];
  forEach(routes, route => {
    if (hasPermission(route, menus)) {
      if (route.children) {
        route.children = filterAsyncRoutes(route.children, menus);
      }
      res.push(route);
    }
  });
  return res;
}
const state = {
  routes: [],
  addRoutes: [],
  menus: [],
  permissions: []
};
const mutations = {
  SET_ROUTES: (state_r, routes) => {
    state_r.addRoutes = routes;
    state_r.routes = constantRoutes.concat(routes);
  },
  updateMenu: (state, arr) => {
    state.menus = arr;
  },
  updatePermission: (state, arr) => {
    state.permissions = arr;
  }
};
const actions = {
  generateRoutes({
    commit,
    state
  }) {
    return new Promise(resolve => {
      const accessedRoutes = filterAsyncRoutes(asyncRoutes, state.menus);
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};